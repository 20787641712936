{/*Public Constant Data */}


{/*Home page list of menu items */}

export const companyName = [
    {id:"company0001", name:"Looomiie Ltd", urlName:"Looomiie", address:{USA: "1178 Broadway, 3rd Floor #3957, New York, NY 10001", UK:"167-169 Great Portland Street , 5th Floor, London, W1W 5PF"} , email:"admin_yxu@Looomiie.com", phone:""},
]

export const productName = [{
    id:"product0001", name:"Looomiie", urlName:"Looomiie",
}]
export const founderName = [
    {id:"founder0001", name:"Yiwei Wang"},
]

export const menuList = [
    {"_id":"menuList000001","name":"About Us", "label":"AboutUs", "sublist":[] },
    {"_id":"menuList000002","name":"Products", "label":"Products","sublist":productName},
    {"_id":"menuList000003","name":"Services", "label":"Services","sublist":[
                                    {"_id":"menuList000031","name":"ML Dataset Labeling by Accounting Professional","label":"labeling","sublist":[]}, 
                                    {"_id":"menuList000032","name":"ML Model Training", "label":"modelTraining","sublist":[{"_id":"menuList000031","name":"test","label":"labeling","sublist":[]}]}
                                  ]},
    {"_id":"menuList000004","name":"Publications", "label":"Publications","sublist":[{"_id":"menuList000041","name":"Articles", "label":"Articles","sublist":[]}]}

]




