import { companyName } from "../../ConstantVariables";
import "./index.css";
function InfoPageTail () {

    const company = companyName.find(item => item.id ==="company0001");


    return (
        <>
        <div className="row height-screen-ad-css background-gray-css p-4">
            {/* column */}
            <div className="col text-white">
                    <div > 
                        {/*company logo*/} 
                    </div> 
                    <div>
                        {/* company name & addresses */}
                        <h3 >{company?.name}</h3>
                        <p>{company?.address.UK}</p>
                    </div>


            </div>
            {/* column */}
            <div className="col align-left-css p-2">
                <h3 className="text-white mx-2 "> Follow Us:</h3>
                <input className="form-control my-2 mx-2 input-size-css" placeholder="Your Email: xyz@abc.com"></input>
                <button type="button" className= "button-newsletter-css fw-bold text-black my-4 mx-2 py-3 px-4">Receive Our Newsletter</button>
            </div>
            {/* column */}
            <div className="col text-color-css column-css text-white align-left-css p-2">
                <div > 
                    <h4 className="no-underline-css text-white fw-bold ">Contact Us</h4>
                </div> 
                <div>
                    {/* company email */}
                    
                    <p>{company?.email}</p>
                    <p>{company?.phone}</p>
                </div>
            </div>
        </div>
        </>
    )
}
export default InfoPageTail;