import axios from "axios"
import {BASE_API} from "../../../Account/User/client"

export const PROFILE_API = `${BASE_API}/users/profile`;

export const getUserProfile=async (userID: string)=>{
    console.log(`testii ${userID}`);    
    const response = await axios.get(`${PROFILE_API}`, 
    
        //configureration JSON object for the HTTP request
        {
        //params: { userID },  // Pass userID as a query parameter
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true// to Include cookies if  backend sets them
    }
    );
    console.log(`user profile received from backend: ${JSON.stringify(response)}`);
    console.log(` response.data is: ${JSON.stringify(response.data)}`);
    //return profile JSON
    return response.data
}



    

// RESTFUL API to submit claim to backend.
// need to write
export const CLAIM_API = `${BASE_API}/users/claims`;

//submit a claim from user input.
export const submitClaim = async (claimTransaction: any) => { 
    console.log({ BASE_API });
    console.log(
        `claim transaction entered client.ts: - submitClaim method, before communicating to server. ClaimTransaction: ${JSON.stringify(claimTransaction)}`
    );

    const response = await axios.post(
        `${CLAIM_API}/submit`, // Endpoint
        claimTransaction, // Request body as JSON
        {
            headers: {
                "Content-Type": "application/json", // Match backend's expectation
            },
            withCredentials: true, // Include cookies if backend sets them
        }
    );
    console.log(`user submition status received from backend: ${JSON.stringify(response)}`);
    console.log(` response.data is: ${JSON.stringify(response.data)}. Response status is: ${response.status}`);
    //return profile JSON
    return response.status;
}

//get the latest 10 , if any, claims by this user from DB.
export const getLatestClaims = async (currentUserID: string) => {
    console.log(`currentUserID before requesting to see the related claims under the client is: ${currentUserID}`)
        const response = await axios.get(`${CLAIM_API}/latest`, 
            {headers: {
                "Content-Type": "application/json", // Match backend's expectation
            },
            withCredentials: true, // Include cookies if backend sets them

        });
    return response.data.claims;
};
//delete a specific claim transaction by requeset_id from DB.
export const deleteRequestItemFromDB = async (request_id: number) => {
    const deleteStatusResponse = await axios.delete(`${CLAIM_API}/delete/${request_id}`, 
        {headers: {
            "Content-Type": "application/json", // Match backend's expectation
        },
        withCredentials: true, // Include cookies if backend sets them
    }
    );
    return deleteStatusResponse.status;
}

//update a claim int the Claim_Transaction table in db
export const updateClaimToDB = async (request_id: any,claimTransaction: any) => {
    console.log(`entered updateClaimTODB in client.ts: claim: ${request_id} claimTransaction: ${JSON.stringify(claimTransaction)}`);
    try {
        const response = await axios.put(`${CLAIM_API}/update/${request_id}`, // Endpoint
            claimTransaction, // Request body as JSON
            {
                headers: {
                    "Content-Type": "application/json", // Ensure backend expects JSON
                },
                withCredentials: true, // Include cookies for authentication
            }
        );
        console.log(`Update response: ${JSON.stringify(response.data)}`);
        return response.status; // Return the server's response
    } catch (error) {
        console.error(`Error updating claim: ${error}`);
        throw error; // Throw the error to handle it in the calling function
    }
}