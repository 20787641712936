import LogoExpenseAI_Large from "../../../Logos/Logo_ExpenseAI.tsx/Logo_ExpenseAI_Large";
import './index.css';
import * as client from "./client";
import {  useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import ErrorReport from "./errorReport";
import { setError } from "../../../errorReducer";

import { useEffect } from "react";
import { useState } from "react";
import { ExpenseAIState } from "../../../store";
import { setProfile} from "./profileReducer";

// Define the Claim data type 
interface Claim {
    request_id: number;
    
    request_timestamp: string;
    currency: string;
    claim_amount: number;
    input_memo:string;
    event: string;
    status: string;
}
function Homepage_after_signin() {
    const dispatch = useDispatch();
    //Get the userID from the url.
    const { currentUserID } = useParams();
    const userProfileInReducer = useSelector((state:ExpenseAIState)=>state.profileReducer.profileData);
    const userProfile = userProfileInReducer.profile;
    const userCompanyName = userProfileInReducer.company_name;
    const navigate = useNavigate();
    const [claimTransaction, setClaimTransaction] = useState({currency:"USD", claim_amount:0.00, input_memo:"", event:"" });
    const [errorMessage, setErrorMessage] = useState("");
    const [inputFormError, setInputFormError] = useState(false);
    const [submissionSuccessBool, setSubmissionSuccessBool] = useState(false);
    const [latestClaims, setLatestClaims] = useState<Claim[]>([]);
    const [selectedClaim, setSelectedClaim] = useState<Claim>({request_id: 0, request_timestamp: "",currency: "",claim_amount: 0,input_memo:"",event: "", status: ""});
    const [claimDeleted, setClaimDeleted] = useState(false);
    const [claimUpdating, setClaimUpdating] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);

    useEffect(()=> {
        
        //if userID exists, get the user's profile data.
        if (currentUserID) {

            console.log(`currentUserID is : ${currentUserID}`);
            const fetchUserProfile = async (id:string) => {
                try {
                    /*input userid to get the following data:
                       1. user profile JSON object
                       2. user companyName string */
                    const userProfileResponseReceived = await client.getUserProfile(id);
                    console.log(`### user profile : ${JSON.stringify(userProfileResponseReceived)}`);
                    
                    dispatch(setProfile(userProfileResponseReceived));
                    
                    
                }
                catch (error) {
                    //if error occors, navigate to error page.
                    console.log(`error: ${error}`);
                    dispatch(setError(error));
                    navigate(`/errorMessage`);
                }
            };
            //Call the fatchUserProfile function to fetch user profile.  
            fetchUserProfile(currentUserID);
            /**
             * Get the latest 10 user claims from DB.
             */
            const fetchLatestClaims = async (currentUserID:string) => {
                try {
                    const claims = await client.getLatestClaims(currentUserID);
                    setLatestClaims(claims);

                    console.log(`Latest claims: ${JSON.stringify(claims)}`);
                } catch (error) {
                    console.error("Error fetching latest claims:", error);
                }
            };
            //Call the function to fetch the latest claims by the user.
            fetchLatestClaims(currentUserID);
            




            }
            }
            
        ,[currentUserID,setProfile, submissionSuccessBool, claimDeleted, updateSuccess]);
    
    //method to submit claim to backend DB.
    const submitClaim = async (claimTransaction:any) => {
        //clean up credentials to make all attributes empty strings
        
        
            setInputFormError(false);
            if (claimTransaction.claim_amount < 0 || claimTransaction.claim_amount == 0 ) {
                setInputFormError(true);
            }
            console.log(`claimTransaction to be saved it: ${JSON.stringify(claimTransaction)}`);
            //if this is not for updating an existing claim, but for creating new claim: 
            if(!claimUpdating) {
                try{
                    const submitted = await client.submitClaim(claimTransaction);
                console.log(`submission status is : ${submitted}`);
                if (submitted) {
                    //notify user submitted.
                    setSubmissionSuccessBool(true);
                    // Reset submissionSuccessBool to false after 10 seconds
                    setTimeout(() => {
                        setSubmissionSuccessBool(false);
                    }, 10000); // 10 seconds
                    //retrieve latest submitted claim on top of claim histry list.
                    }
                }
                catch (err: any) {
                    console.log("Error encountered when submitting claim.");
                    setErrorMessage("Error encountered.  Please try again.");
                }
                

                }
    }

    //method to delete a claim transaction from a list of claims
    const deleteRequestItem = async (claim:Claim) => {
        setClaimDeleted(false); //reset claimDeleted to false to prepare for new deletion.
        const deleteStatus = await client.deleteRequestItemFromDB(claim.request_id);
        if (deleteStatus === 200) {
            console.log("Claim deleted successfully");
            //
            // Refresh the claim list or update the state
            setClaimDeleted(true); // Call your function to refresh the claims list
            //then the list of latest claims displayed in bottom div will be refreshed to reflect the update due to delete.

        }
    }
    const updateRequestItem = (claim:Claim) => {
        console.log(`entered updateRequestItem - to prepare to update`)
        setClaimUpdating(true);
        //set the selectedClaim to the selected claim.
        setSelectedClaim(claim);
        // Update the claimTransaction state with the selected claim's values

        setClaimTransaction({
        ...claimTransaction, // Preserve other fields
        
        currency: claim.currency,
        claim_amount: claim.claim_amount,
        input_memo:claim.input_memo,
        event:claim.event
        //Then, if the claimUpdating bool variable is set to true, then later if user clicks the "Submit " button, the system stores the updated values to the old selected claim request in dababase, instead of adding a new request item.
    });
        //set the field to show the value of the selected claim's property values in the Components' value attribute.
            

        
    }
    const updateRequestItemSubmit= async (request_id:any, claimTransaction:any)=> {
        try {
            console.log(`claimTransaction updated befor submitting is: ${JSON.stringify(claimTransaction)}`)
            const updatedStatus = await client.updateClaimToDB (request_id,claimTransaction);
            if (updatedStatus == 200) {
                setUpdateSuccess(true);
            }
        }
        catch {

        }
    }
    
    return(
        <>
        <div className="container-fluid full-screen-css background-color-signin ">
            <div className="row"> 
                <div className="col-2 p-2">
                    {/*Product Logo*/}
                    <LogoExpenseAI_Large/>
                </div>
                
            </div>
            {/*The div that shows the user profile*/}
            <div className="profile-div-frame text-black">
               
                {userProfile  && userProfile.firstName ? (
                            
                            <p className="font-weight-hello-css profile-greating-css custom-font m-2">Hello! {userProfile.firstName}</p>
                        ) : (
                            <span className="">Loading profile...</span> // Display this while loading
                        )}
                
                <div className="container m-2">
                    <div className="row ">
                        <div className="col-4">
                          <span className="float-start fw-bold ">Profile: </span>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-4 text-end">
                        Employee ID:
                        </div>
                        <div className="col-8 text-start">
                        {userProfile.employee_id}
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-4 text-end">
                        Email:
                        </div>
                        <div className="col-8 text-start">
                        {userProfile.email}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 text-end">
                        Company:
                        </div>
                        <div className="col-8 text-start">
                        {userCompanyName}
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-4 text-end">
                        Title:
                        </div>
                        <div className="col-8 text-start">
                        {userProfile.title}
                        </div>
                        
                    </div>
                </div>
                
                

            </div>
            {/*the first container that holds the invoice info and the extracted info for user submission*/}
            <div className="frame-backgroud-color-white ">
                {/*invoice section for current expense to be claimed*/}
                <div className = "row  p-4">
                    
                        
                        {/*the right side invoice info input  section*/}
                        <div className="col-6 ">
                            <div className="container" >
                                <div className="row">
                                    <div className="col-6">
                                        <label className="p-2 float-end fw-bold" htmlFor="INPUTuploadPDF">Upload Invoice:</label>
                                    </div>
                                    <div className="col-6">
                                        <input className="p-2 float-start" type="file" id="INPUTuploadPDF" ></input>
                                    </div>
                                    

                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <label className="p-2 float-end fw-bold" htmlFor="INPUTinvoiceamount">Amount Claiming:</label>
                                    </div>
                                    <div className="col-6">
                                        <div className="currency-input-container">
                                            <select id = "id_input_currency"className="p-1 m-2" value={claimTransaction.currency} onChange={(e)=>{console.log("Selected currency:", e.target.value);setClaimTransaction({...claimTransaction, currency:e.target.value})}}>
                                                <option value="USD" className=" " >USD</option>
                                                <option value="GBP" className="" >GBP</option>
                                                <option value="HKD" className="">HKD</option>
                                            </select>
                                            <input id = "id_input_claim_amount" className="float-start width-small-css" type="number" value={claimTransaction.claim_amount}onChange={(e)=>setClaimTransaction({...claimTransaction, claim_amount: parseFloat(e.target.value)|| 0.00} )} ></input>
                                        </div>
                                        
                                    </div>
                                </div>
                                {/*
                                <div className="row">
                                    <div className="col-6">
                                        <label className="p-2 float-end fw-bold" htmlFor="INPUTcostcenter">Cost Center:</label>

                                    </div>
                                    <div className="col-6">
                                        <select className="p-2 m-2 float-start">
                                            <option value="project1" className=" " >Project 1</option> 
                                            <option value="project2" className="" >Project 2</option> 
                                        </select>
                                    </div>
                                    

                                </div>
                                */}

                                <div className="row">
                                    <div className="col-6">
                                        <label className="p-2 float-end fw-bold" >Memo:</label>

                                    </div>
                                    <div className="col-6">
                                        <input className="m-2 float-start width-small-css " type="text" value={claimTransaction.input_memo} onChange = {(e)=>setClaimTransaction({...claimTransaction, input_memo:e.target.value})}></input>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <label className="p-2 float-end fw-bold" >Event:</label>

                                    </div>
                                    <div className="col-6">
                                        <input className="m-2 float-start width-small-css " type="text" value={claimTransaction.event} onChange={(e) => setClaimTransaction({...claimTransaction, event: e.target.value })}></input>
                                    </div>


                                </div>
                               




                            </div>
                            
                            
                            
                            
                        </div>
                        <div className="col-6 border-style-divider-css">
                            <div className="container ">
                                <div className="row fw-bold">
                                    Preview:
                                </div>
                                <div className="row fw-bold css-height-of-div ">
                                    
                                </div>
                                
                                
                            </div>
                        </div>
                    
                </div>
                {/* the submit button row at the bottem of entire row of the claim block*/}
                
                {inputFormError && (
                            <div className="row">
                                <div className="col text-danger">
                                    Input not valid. Please double check. Number must be greater than 0. 
                                </div>
                            </div>
                            
                        )}
                {submissionSuccessBool && (
                            <div className="row">
                                <div className="col text-success">
                                    Submission Successful!  Approval Status pending... 
                                </div>
                            </div>
                )}
                    
                
               
                <div className="row">
                    <div className="col">
                        {!claimUpdating && 
                            <button className="submit-button-long-css fw-bold" onClick={(e)=>submitClaim(claimTransaction)}>Submit</button>
                        }
                        {claimUpdating && 
                            <button className="submit-button-long-css fw-bold" onClick={(e)=>updateRequestItemSubmit(selectedClaim.request_id, claimTransaction)}>Update</button>
                            }
                        
                        
                    </div>

                    
                </div>

            </div>
                {/*menu section at bottom*/}
            <div className=" frame-backgroud-color-white ">
                <div className="row p-2">
                
                        <div className="col min-width-small-item">
                            <button className="btn text-black fw-bold">Claim History</button>
                        </div>
                </div>
                <div className="row p-2">
            
                        <div className="col min-width-small-item">
                            <div className="container">
                                <div className="row">
                                    <div className="col fw-bold">
                                        Request ID
                                    </div>
                                    <div className="col fw-bold">
                                        Claim Date
                                    </div>
                                    <div className="col fw-bold">
                                        Event
                                    </div>
                                    <div className="col fw-bold">
                                        Status
                                    </div>
                                    <div className="col fw-bold">
                                        Currency
                                    </div>
                                    <div className="col fw-bold">
                                        Amount Requested
                                    </div>
                                    <div className="col fw-bold">
                                        Action
                                    </div>
                                </div>

                                {/*the data detail row*/}
                            
                                {/* Iterate over the latestClaims array to generate rows */}
                                {latestClaims && latestClaims.length > 0 ? (
                                    latestClaims.map((claim, index) => (
                                        <div className="row" key={index}>
                                            <div className="col">
                                                {claim.request_id} {/* Display Claim ID */}
                                            </div>
                                            <div className="col">
                                                {new Date(claim.request_timestamp).toLocaleString()} {/* Format and display Claim Date */}
                                            </div>
                                            <div className="col">
                                                {claim.event} {/* Display Event */}
                                            </div>
                                            <div className="col">
                                                {claim.status} {/* Display Status */}
                                            </div>
                                            <div className="col">
                                                {claim.currency}
                                            </div>

                                            <div className="col">
                                                ${claim.claim_amount.toFixed(2)} {/* Display Amount Requested */}
                                            </div>
                                            <div className="col">
                                                {/*button to delete */}
                                                <button className="button-customized-small text-size-s btn-red-css" onClick={(e)=>deleteRequestItem(claim)}>Withdraw</button> {/* Example Action */}
                                                <button className=" button-customized-small text-size-s btn-blue-css" onClick={(e)=>updateRequestItem(claim)}>Update</button>    
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="row">
                                        <div className="col">
                                            No claims found.
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                </div>

            </div>
            

        </div>
    
        </>
    )
};


export default Homepage_after_signin;