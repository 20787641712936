import axios from "axios";

export const BASE_API = process.env.REACT_APP_API_BASE || 'http://localhost:8000';
export const USERS_API = `${BASE_API}/users`;

export interface User { 
    employee_id: string; company_name: string; username: string; password: string; email: string; role: string;
firstName: string, lastName: string , dateOfBirth:string
};


export const signin = async (credentials:User) => {
    //extract the username and password and create a new JSON object that has only the username and password data.
    //const credentials_signin = {username:credentials.username, password:credentials.password}
    const formData = new URLSearchParams();
    formData.append("username", credentials.username);
    formData.append("password", credentials.password); 
    //console.log(`credentials went into client-signin: ${JSON.stringify(credentials)}`);
    const response = await axios.post( `${USERS_API}/signin`, formData.toString(), {
        //This header tells the server the type of data being sent in the body of the request. In this case, "application/x-www-form-urlencoded" indicates that the data is encoded in URL-encoded format, which is a common format used for form submissions.
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true// to Include cookies if  backend sets them
    } );
    console.log(`printing response ${JSON.stringify(response)}`);
    return response.data;
};
//Sign up API : done
export const signup = async (credentials:User) => {
    console.log("BASE_API:", BASE_API);
    console.log(`printed credentials from front-end: ${JSON.stringify(credentials)}`);
    //console.log(`credentials went into client-signin: ${JSON.stringify(credentials)}`);
    const response = await axios.post( `${USERS_API}/signup`, credentials, {
        withCredentials: true
    } );
    //If something goes wrong,  raise an HTTPException
    
    //return {"message": "Signup successful"}
    console.log(`response:${JSON.stringify(response)}`);
    
    return response;
    
    };

export const getClientCompaniesFromDB = async()=>{
    console.log("s")
    const list_of_company_names = await axios.get(`${USERS_API}/signup/getClientCompanies`);
    return list_of_company_names;
}