import { useState } from "react";
import { useEffect } from "react";
import {  useNavigate } from "react-router";
import "./index.css";
import { companyName } from "../../ConstantVariables";
import { useLocation } from 'react-router-dom';
import LogoExpenseAI_Large from "../../Logos/Logo_ExpenseAI.tsx/Logo_ExpenseAI_Large";
import * as client from "./client";
import { IoClose } from "react-icons/io5";


export default function SignIn_or_SignUp () {
    
    {/*check if the purpose of this page is signin or signup. ********************/}
    const location = useLocation();
    const [purpose, setPurpose] = useState("");
    const purposeDisplayJSONObjects = [{purpose:"Signin", title:"SIGN IN", buttonWord:"Sign In", altQuestion:"Not yet registered?", alt:"Sign Up", altPath:"Signup"},
                                {purpose:"Signup",title:"SIGN UP", buttonWord:"Sign Up", altQuestion: "Already have an account?", alt:"Sign In", altPath:"Signin"}];

    
    useEffect (()=>{
        
        function getPurpose(url:any) {
            
            if(url.includes("Signin")) {
                return "Signin";
            }
            else if(url.includes("Signup")) {
                return "Signup";
            }
            
            console.log("purpose not determinable. Must have purpose (sign in / sign up). check error.");
            return "";
        }
       
        const x = getPurpose(location.pathname);
        if (x) {
            setPurpose(x);
            
        }
        
    },[location.pathname]);
    {/***************blocks of code to get the list of client companies from the backend************ */}
    const [ClientCompaniesFromDB, setClientCompaniesFromDB] = useState([])
    //get the list of client company names from back-end database
    useEffect(()=>{
        //if purpose is to sign in , clean up the username and password in credential to let user reinput.
        if(purpose == "Signin") {
            setCredentials({...credentials, username:"", password:""});
        }
        //if purpose is to sign up, get the list of company names from backend database.
        if(purpose === "Signup"){
        const fetchClientCompanies=async()=>{
            try{
                const list_of_client_companies = await client.getClientCompaniesFromDB();
                console.log(`list of client companies: ${JSON.stringify(list_of_client_companies.data)}`);
                setClientCompaniesFromDB(list_of_client_companies.data);
                // Set the initial default company_name to be the first string of the list_of_client_companies.
                if (list_of_client_companies.data.length > 0) {
                    setCredentials({...credentials,company_name: list_of_client_companies.data[0]});
                }
            }


            catch (error) {
                console.error("error fetching client companies from DB.");
            }
        };
        fetchClientCompanies();
    }
        
    }, [purpose])
    {/**********************************************************************************************/}

    {/******************************************************************** */}
    const [errorMessage, setErrorMessage] = useState("");

    const [credentials, setCredentials] = useState<any>({ employee_id: "",company_name:"",
    username: "", password: "", email:"", role: "", firstName: "", lastName: "",  dateOfBirth:""
    });

    const [showSignUpSuccess, setShowSignUpSuccess] = useState(false);
    const navigate = useNavigate();
   
    const signin_OR_signup = async () => {
        //clean up credentials to make all attributes empty strings
        setCredentials({...credentials, username:"", password:""});
        if (purpose === 'Signin') {
            
            try {
                const response_userID = await client.signin(credentials);
                console.log(response_userID.employee_id);  // int , need to convert to string.
                

                //STORE THE token into local storage that can be passed to another page.  Redux
                        //Security: Avoid storing tokens in localStorage or sessionStorage unless necessary. Use HttpOnly cookies when possible.
                        //Centralized State Management: Manage authentication state in a centralized manner, using React Context or Redux to ensure consistency across the app.
                        //Error Handling: Implement robust error handling to manage scenarios where token storage fails, or the user is unauthorized.
                        //Create reusable components or hooks for handling authentication logic and navigation to keep your codebase clean and maintainable.
                //Token Expiry: Make sure to handle token expiration. One approach is to periodically refresh the token or check its validity before performing actions that require authentication.
                navigate(`/Account/Profile/${response_userID.employee_id}`); // added.employee_id to get the string of employee_id from response object
            }
            catch(error:any) {
                console.log(error);
                setErrorMessage("Error encountered.  Please try again.")
            }
        }

        {/*If purpose is sign up, register the new user into database*/}
        if (purpose === 'Signup') {
            setErrorMessage(""); // when user clicks the signup button, error message refresh to "" instead of showing error message for previous attempts.
            try {
                const response = await client.signup(credentials);
                if(response.status === 200) {
                    // Navigate to the Sign-In page with a success message flag
                navigate('/Account/Signin');  
                setShowSignUpSuccess(true);
                }
                
                
            }
            catch(error:any) {
                console.log(error);
                setErrorMessage("Error encountered during registration.  Please try again.")
            }
        }
        
        
        
        
    
   
    };

    return(
<div className="container-fluid full-screen-css background-color-signin">
    <div className="row"> 
        <div className="col-2 p-2">
            {/*Product Logo*/}
            <LogoExpenseAI_Large/>
        </div>
        
    </div>
    <div className = "row   p-2">
        <div className="col-12 center-content-css ">
            <div className="flex-column-frame-css ">
            
            

                    <span className="antonio-font-signIn title-sign-in-responsive width-100-of-parent">{purposeDisplayJSONObjects.find(item => item.purpose===purpose)?.title}</span><br/>
                    <div>
                        <div className="text-danger">{errorMessage}</div>
                        
                    </div>

                    <div>
                    {/* the sign-up-success dialog that shows only after user sign up success */}
                    {showSignUpSuccess && (
                        <div className="dialog text-success-css fw-bold mb-2 ">
                            <div className="row p-0 m-0">
                            <button className=" button-dialog-close-css text-color-signin rounded text-end" onClick={() => setShowSignUpSuccess(false)}><IoClose /></button>
                            </div>
                            <center>Sign up was successful! </center>
                            <center>You can now sign in with your new account.</center>
                            
                        </div>
                    )}
                </div>


                    {purpose==="Signup" && (
                        <>
                        {/*employee email*/}
                        <div className="col-12 flex-col-css width-of-div-input align-items-start-css gap-1">
                        <label htmlFor="inputEmail" className="text-color-signin " > Email</label>
                        <input id="inputEmail" value={credentials.email} className=" width-input-css m-2  input-style-css input-outline-css align-self-center-css"  placeholder="abc@gmail.com" onChange={(e) =>
                    setCredentials({ ...credentials, email: e.target.value })}/>
                        </div>

                        {/*employee id*/}
                        <div className="col-12 flex-col-css width-of-div-input align-items-start-css gap-1">
                        <label htmlFor="employeeID" className="text-color-signin " > Employee ID</label>
                        <input id="employeeID" value={credentials.employee_id} className=" width-input-css m-2  input-style-css input-outline-css align-self-center-css"  placeholder="Employee ID Number" onChange={(e) =>
                    setCredentials({ ...credentials, employee_id: e.target.value })}/>
                        </div>
                        {/*Company name*/}
                        <div className="col-12 flex-col-css width-of-div-input align-items-start-css gap-1">
                            <label htmlFor="companyName" className="text-color-signin " > Company Name</label>
                            <select  id="companyName" className="width-input-css m-2  input-style-css input-outline-css align-self-center-css" onChange={(e) =>
                            setCredentials({ ...credentials, company_name: e.target.value })}>
                                {ClientCompaniesFromDB.map((item, index)=>(
                                    <option value={item} className="text-black" >{item}</option>
                                ))}
                                    
                                    
                            </select>
                        </div>
                        {/*first name        employee_id: string; username: string; password: string; role: string;
firstName: string, lastName: string */}
                        <div className="col-12 flex-col-css width-of-div-input align-items-start-css gap-1">
                        <label htmlFor="inputFirstName" className="text-color-signin " > Legal First Name</label>
                        <input id="inputFirstName" value={credentials.firstName} className=" width-input-css m-2  input-style-css input-outline-css align-self-center-css"  placeholder="Legal First Name" onChange={(e) =>
                    setCredentials({ ...credentials, firstName: e.target.value })}/>
                        </div>

                        {/*last name        employee_id: string; username: string; password: string; role: string;
firstName: string, lastName: string */}
                        <div className="col-12 flex-col-css width-of-div-input align-items-start-css gap-1">
                        <label htmlFor="inputLastName" className="text-color-signin " > Legal Last Name</label>
                        <input id="inputLastName" value={credentials.lastName} className=" width-input-css m-2  input-style-css input-outline-css align-self-center-css"  placeholder="Legal Last Name" onChange={(e) =>
                    setCredentials({ ...credentials, lastName: e.target.value })}/>
                        </div>
                        
                        {/*Date of Birth        employee_id: string; username: string; password: string; role: string;
firstName: string, lastName: string */}
                        <div className="col-12 flex-col-css width-of-div-input align-items-start-css gap-1">
                        <label htmlFor="dateOfBirth" className="text-color-signin " > Date of Birth</label>
                        <input id="dateOfBirth" type="date" value={credentials.dateOfBirth} className=" width-input-css m-2  input-style-css input-outline-css align-self-center-css"   onChange={(e) =>
                    setCredentials({ ...credentials, dateOfBirth: e.target.value })}/>
                        </div>

                        {/*role       employee_id: string; username: string; password: string; role: string;
firstName: string, lastName: string */}
                        <div className="col-12 flex-col-css width-of-div-input align-items-start-css gap-1">
                        <label htmlFor="inputRole" className="text-color-signin " > Role</label>
                        <select defaultValue={"Employee"} id="inputRole" className="width-input-css m-2  input-style-css input-outline-css align-self-center-css" onChange={(e) =>
                    setCredentials({ ...credentials, role: e.target.value })}>
                            <option value="Employee" className="text-black" >Employee</option>
                            <option value="Manager" className="text-black">Manager</option>
                            <option value="Admin" className="text-black">Admin</option>
                        </select>
                        </div>

                        </>
                    )}

                    
                    <div className="col-12 flex-col-css width-of-div-input align-items-start-css gap-1">
                            <label htmlFor="inputUserName" className="text-color-signin " >Username</label>
                            <input id="inputUserName" value={credentials.username} className=" width-input-css m-2  input-style-css input-outline-css align-self-center-css"  placeholder="username" onChange={(e) =>
                        setCredentials({ ...credentials, username: e.target.value })}/>
                    </div>
                        
                    <div className="col-12 flex-col-css width-of-div-input align-items-start-css gap-1">
                            <label htmlFor="inputPassword" className="text-color-signin " >Password</label>
                            <input type="password" id="inputPassword" value={credentials.password} placeholder="Your password (8 characters minimum)" className=" width-input-css m-2  input-style-css input-outline-css align-self-center-css"   onChange={(e) =>
                        setCredentials({ ...credentials, password: e.target.value })}/>
                    </div>
                    <div className=" col-12 flex-col-css width-of-div-input gap-1S">
                        <button type="button" className="button-sign-in margin-top-button align-self-center-css" onClick={signin_OR_signup}> {purposeDisplayJSONObjects.find(item => item.purpose ===purpose)?.buttonWord}</button>
                        <div className="flex-align-same-line">
                        
                        </div>
                        
                    </div>
                    <div className="row ">
                    {/*the link to shift to signin/signup page.  on click, the errorMessage is reset to "" instead of showing. */}
                    <span className="text-color-signin inline-nowrap-css">{purposeDisplayJSONObjects.find(item=>item.purpose===purpose)?.altQuestion}</span><a className="text-color-signin inline-nowrap-css m-2" onClick={()=>{setErrorMessage(""); setShowSignUpSuccess(false); navigate(`/Account/${purposeDisplayJSONObjects.find(item=>item.purpose===purpose)?.altPath}`)}}> {purposeDisplayJSONObjects.find(item=>item.purpose===purpose)?.alt}</a>
                    </div>
            </div>

        </div>
    </div>
</div>

    )

}