import "./style.css";
import { productName } from "../../ConstantVariables";
export default function LogoExpenseAI_Large() {





    return (
        <>
            {/* need to change href 's Looomiie into whatever productname that is passed via the current url  */}
            <a href="/#/Home/Looomiie" className="fw-bold no-decoration  mx-2 my-4 logo-css custom-font glow-css font-size-logo-large nowrap-text">{productName.find(item=>item.id=="product0001")?.name}</a>
        </>
    )
}